<template>
  <div class="search_college">
    <div class="nav">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="nav_">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>智能查询</el-breadcrumb-item>
        <el-breadcrumb-item>找大学</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="search">
        <el-input placeholder="请输入关键词" v-model="check.name" @keyup.enter.native="doSearch" class="input-with-select">
          <el-button slot="append" @click="doSearch">
            <img src="@/assets/img/home/search.png" alt="" />
          </el-button>
        </el-input>
      </div>
    </div>
    <div class="tabs">
      <!--            办学类型-->
      <el-row class="search_line">
        <el-col :span="3"><span class="title">办学类型></span> </el-col>
        <el-col :span="21">
          <span :class="{ act: check.degree === null }" @click="choose('degree', null, 0)">全部</span>
          <span :class="{ act: check.degree === 0 }" @click="choose('degree', 0, 0)">普通本科</span>
          <span :class="{ act: check.degree === 1 }" @click="choose('degree', 1, 0)">专科（高职）</span>
        </el-col>
      </el-row>
      <!--            院校性质-->
      <el-row class="search_line">
        <el-col :span="3">
          <span class="title">院校性质></span>
        </el-col>
        <el-col :span="21">
          <span :class="{ item: true, act: check.nature === null }" @click="choose('nature', null, 0)">全部</span>
          <span :class="{ item: true, act: check.nature === 1 }" @click="choose('nature', 1, 0)">公办</span>
          <span :class="{ item: true, act: check.nature === 2 }" @click="choose('nature', 2, 0)">民办</span>
          <span :class="{ item: true, act: check.nature === 3 }" @click="choose('nature', 3, 0)">中外合作办学</span>
          <span :class="{ item: true, act: check.nature === 4 }" @click="choose('nature', 4, 0)">内地与港澳台地区合作办学</span>
        </el-col>
      </el-row>
      <!--            所属地区-->
      <el-row class="search_line">
        <el-col :span="3"><span class="title">所属地区></span></el-col>
        <el-col :span="21">
          <span :class="{ item: true, act: check.city_id.length === 0 }" @click="choose('city_id', -1, 1)">全部</span>
          <span v-for="(item, i) in cityList" :key="i" :class="{ act: check.city_id.indexOf(item.city_code) > -1 }"
            @click="choose('city_id', item.city_code, 1)">{{ item.city }}</span>
        </el-col>
      </el-row>
      <!--            院校类型-->
      <el-row class="search_line">
        <el-col :span="3"><span class="title">院校类型></span></el-col>
        <el-col :span="21">
          <span :class="{ item: true, act: check.school_type_id.length === 0 }"
            @click="choose('school_type_id', -1, 1)">全部</span>
          <span v-for="(item, i) in schoolTypeList" :key="i"
            :class="{ act: check.school_type_id.indexOf(item.id) > -1 }"
            @click="choose('school_type_id', item.id, 1)">{{ item.name }}</span>
        </el-col>
      </el-row>

      <!-- 招生批次 -->

      <el-row class="search_line">
        <el-col :span="3">
          <span class="title">招生批次></span>
        </el-col>
        <el-col :span="21">
          <span :class="{ item: true, act: check.batch == null }" @click="choose('batch', null, 0)">全部</span>
          <span v-for="(item, i) in schoolBatch" :key="i" :class="{
            item: true,
            act: check.batch == item.id,
          }" @click="choose('batch', item.id, 0)">{{ item.name }}
          </span>
        </el-col>
      </el-row>
      <!--            院校特色-->
      <el-row class="search_line">
        <el-col :span="3"><span class="title">院校特色></span></el-col>
        <el-col :span="21">
          <span :class="{ item: true, act: check.schoolTipId.length === 0 }"
            @click="choose('schoolTipId', -1, 1)">全部</span>
          <span v-for="(item, i) in schoolTipList" :key="i" :class="{
            item: true,
            act: check.schoolTipId.indexOf(item.id) > -1,
          }" @click="choose('schoolTipId', item.id, 1)">{{ item.name }}
          </span>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import datasss from "@/assets/data/conditional-search.js";
export default {
  name: "ConditionalSearch",
  props: ["condition"],
  data() {
    return {
      check: {
        name: "",
        degree: null,
        nature: null,
        schoolTipId: [],
        school_type_id: [],
        city_id: [],
        batch: null
      },
      cityList: [],
      schoolTipList: [
        {
          id: "11",
          name: "C9",
          type: "0",
        },
        {
          id: "5",
          name: "985",
          type: "0",
        },
        {
          id: "6",
          name: "211",
          type: "0",
        },
        {
          id: "7",
          name: "双一流建设高校",
          type: "0",
        },
        {
          id: "9",
          name: "教育部直属",
          type: "0",
        },
        {
          id: "12",
          name: "研究生院",
          type: "0",
        },
        {
          id: "10",
          name: "其他部属",
          type: "0",
        },
        {
          id: "13",
          name: "国家示范",
          type: "0",
        },
        {
          id: "14",
          name: "国家骨干",
          type: "0",
        },
        {
          id: "15",
          name: "双高计划",
          type: "0",
        },

      ],
      schoolTypeList: [
        {
          id: "17",
          name: "财经类",
        },
        {
          id: "18",
          name: "理工类",
        },
        {
          id: "19",
          name: "农林类",
        },
        {
          id: "20",
          name: "综合类",
        },
        {
          id: "21",
          name: "政法类",
        },
        {
          id: "22",
          name: "师范类",
        },
        {
          id: "23",
          name: "语言类",
        },
        {
          id: "24",
          name: "医药类",
        },
        {
          id: "26",
          name: "民族类",
        },
        {
          id: "27",
          name: "艺术类",
        },
        {
          id: "28",
          name: "体育类",
        },
        {
          id: "29",
          name: "军事类",
        },
      ],
      schoolBatch: [
        {
          id: "1",
          name: "本科一批",
        },
        {
          id: "2",
          name: "本科二批",
        },
        {
          id: "3",
          name: "高职高专批",
        },
        {
          id: "6",
          name: "提前录取院校",
        },

        {
          id: "4",
          name: "国家专项",
        },
        {
          id: "5",
          name: "地方专项",
        },
      
      ],
    };
  },
  created() {
    this.cityList = datasss.city;
  },
  methods: {
    choose(type, val, isArr) {
      if (isArr === 1) {
        if (val === -1) {
          this.check[type] = [];
        } else {
          const index = this.check[type].indexOf(val);
          if (index > -1) {
            this.check[type].splice(index, 1);
          } else {
            this.check[type].push(val);
          }
        }
      } else if (isArr === 0) {
        this.check[type] = val;
      }
      this.$emit("onSearch", this.check);
    },
    doSearch() {
      this.$emit("onSearch", this.check);
    },
  },
};
</script>
<style lang="less">
.search_line {
  border-bottom: 1px solid #dfdfdf;
  padding: 10px 0;
}

.search_line:last-child {
  border: 0;
}
</style>
<style lang="less" scoped>
.nav {
  margin:  10px auto;

  .nav_ {
    width: 75%;
    display: inline-block;
  }

  .search {
    width: 25%;
    display: inline-block;
  }
}

.search_college {
  .tabs {
    color: #666666;
    letter-spacing: 2px;
    border: 1px solid #dfdfdf;
    padding: 15px;

    .title {
      font-size: 18px;
      color: #333333;
      font-weight: 500;
      text-align: center;
    }

    span {
      display: inline-block;
      padding: 0 12px;
      line-height: 27px;
      margin-bottom: 3px;

      &:hover {
        background: #2b7bf3;
        color: white;
      }
    }
  }

  .act {
    background-color: #2b7bf3;
    color: #ffffff;
  }
}
</style>