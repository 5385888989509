<template>
    <div class="page">
        <ConditionalSearch :condition="data" @onSearch="onSearch" />
        <div style="height: 40px;border-bottom: 1px solid #dfdfdf"></div>
        <div v-loading="loading" style="min-height:300px">
            <CollegeList v-for="(item, i) in list" :key="i" :item="item"></CollegeList>
        </div>
        <!--        分页-->
        <div class="pagination">
            <el-pagination :hide-on-single-page="true" :page-size="pageSize" :current-page="pageNum" :pager-count="11"
                :total="total" class="page-box" layout="prev, pager, next" @current-change="pageChange" />
        </div>
    </div>
</template>

<script>
import ConditionalSearch from '@/components/ConditionalSearch';
import CollegeList from '@/components/CollegeList';

export default {
    name: "LookCollege",
    components: {
        ConditionalSearch,
        CollegeList
    },
    data() {
        return {
            data: {},
            check: {},
            search: '',
            list: [],
            total: 0,
            pageNum: 1,
            pageSize: 10,
            loading: true
        }
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0
        this.getCollege({})
    },
    methods: {
        onSearch(data) {
            this.check = data
            this.pageNum = 1
            this.getCollege(data)
        },
        onSearchName() {
            this.check.name = this.search
            this.getCollege(this.check)
        },
        getCollege(obj) {
            this.loading = true
            let data = obj
            this.data.name = this.search
            data.pageNum = this.pageNum
            data.pageSize = this.pageSize
            this.$fecth.post('school/getSchoolList', data).then((res) => {
                this.loading = false
                this.list = res.lists
                this.total = res.total
            })
        },
        pageChange(page) {
            document.body.scrollTop = document.documentElement.scrollTop = 650
            this.pageNum = page
            this.getCollege(this.check)
        }
    }
}
</script>

<style lang="less" scoped>
.pagination {
    padding: 40px 0;

    .page-box {
        text-align: center;
    }
}
</style>